import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '../../../hooks/useQuery';
import { useTranslate } from '../../../hooks/useTranslate';
import CustomStepper from '../../shared/Stepper';
import VerifyEmail from './VerifyEmail';
import { AuthContext } from '../../../contexts/auth-context';
import { deepEqual, isEmptyArray, isNotUndefined } from '../../../utils';
import { LOCALSTORAGE, PARTNERED_INSTITUTIONS } from '../../../utils/constants';
import Skeleton from '../../shared/Skeleton';
import {
  CreateRequestReducerActions,
  CreateRequestReducerActionTypes,
  CreateRequestReducerState,
  HealthCareProvider,
  PersonalInformationType
} from '../../../types';
import InstitutionPhysicianPicker from './InstitutionPhysician';
import Authorization from './Authorization';
import PersonalInformation from './PersonalInformation';
import ReviewSummary from './ReviewSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      width: '95%'
    }
  },
  stepperLabelSkeleton: {
    height: '5rem',
    [theme.breakpoints.up('widescreen')]: {
      height: '2.5rem'
    }
  }
}));

function reducer(state: CreateRequestReducerState, action: CreateRequestReducerActions): CreateRequestReducerState {
  const { healthCareProviders } = state;
  let updatedHealthCareProviders: HealthCareProvider[];
  switch (action.type) {
    case CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER:
      updatedHealthCareProviders = [
        ...healthCareProviders,
        {
          institution: action.payload.institution,
          address: {
            ...action.payload.address,
            name: action.payload.address.name
              ? `${action.payload.institution.institution_name} – ${action.payload.address.name}`
              : action.payload.institution.institution_name
          },
          physician: action.payload.physician
        }
      ];
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER:
      updatedHealthCareProviders = healthCareProviders.filter(
        (_, providerIndex) => providerIndex !== action.payload.index
      );
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: action.payload.physician };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: action.payload.notes };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.RESET:
      updatedHealthCareProviders = [];
      return { healthCareProviders: updatedHealthCareProviders };
    default:
      return state;
  }
}

const StepperLabelSkeleton = () => {
  const classes = useStyles();
  return <Skeleton className={classes.stepperLabelSkeleton} />;
};

export interface PersonalInformationRequest {
  user: PersonalInformationType;
  institution_name: keyof typeof PARTNERED_INSTITUTIONS;
}

const NewRequestForm = () => {
  const classes = useStyles();
  const { institution } = useContext(AuthContext);

  const { t, ready: translationFileReady } = useTranslate('requestForm');
  const {
    loading: sendEmailVerificationLoading,
    makeRequest,
    error: sendEmailVerificationError
  } = useMutation<PersonalInformationRequest>('POST');

  const [activeStep, setActiveStep] = useState(0);
  const [disabledSteps, setDisabledSteps] = useState<number[]>([]);
  const [signatureUrl, setSignatureUrl] = useState<string>();
  const [isConsentAuthorized, setIsConsentAuthorized] = useState<boolean>(false);
  const [confirmationTextId, setConfirmationTextId] = useState<number>();
  const [personalInformation, setPersonalInformation] = useState<PersonalInformationType>();

  const [healthCareProvidersState, dispatch] = useReducer(reducer, {
    healthCareProviders: []
  });

  const handleConsentAuthorized = (isAuthorized: boolean, confirmationTextId: number) => {
    setIsConsentAuthorized(isAuthorized);
    setConfirmationTextId(confirmationTextId);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // After Verifiying email disabled the ability to go to that step
      if (prevActiveStep === 1) {
        setDisabledSteps([1]);
      }
      return prevActiveStep + 1;
    });
  };

  const sendEmailVerification = useCallback(
    (data: PersonalInformationRequest, callback?: () => void) => {
      makeRequest(`/verify-email`, data as PersonalInformationRequest).then(() => {
        if (isNotUndefined(callback)) {
          callback();
        }
      });
    },
    [makeRequest]
  );

  const handlePersonalInformationSubmit = useCallback(
    (values: PersonalInformationType) => {
      localStorage.removeItem(LOCALSTORAGE.publicAccessToken);

      // If the personal information does not change do not resend an email
      // verification when moving from personal information step to verification email
      if (deepEqual(personalInformation, { ...values })) {
        handleNext();
      } else {
        sendEmailVerification(
          {
            user: { ...values },
            institution_name: institution as keyof typeof PARTNERED_INSTITUTIONS
          },
          () => {
            handleNext();
            setPersonalInformation({ ...values });
          }
        );
      }
    },
    [institution, sendEmailVerification, personalInformation]
  );

  const saveSignatureUrl = (url: string | undefined) => {
    setSignatureUrl(url);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <CustomStepper
        disabledSteps={disabledSteps}
        steps={[
          {
            label: translationFileReady ? t('personalInformation.title') : <StepperLabelSkeleton />,
            ariaLabel: t('personalInformation.accessibleTitle'),
            disableGoingForward: activeStep === 0,
            content: (
              <PersonalInformation
                personalInformation={personalInformation}
                onSubmit={handlePersonalInformationSubmit}
                sendEmailVerificationError={sendEmailVerificationError}
                sendEmailVerificationLoading={sendEmailVerificationLoading}
              />
            )
          },
          {
            label: translationFileReady ? t('emailVerification.title') : <StepperLabelSkeleton />,
            ariaLabel: t('emailVerification.accessibleTitle'),
            disableGoingForward: !localStorage.getItem(LOCALSTORAGE.publicAccessToken),
            content: (
              <VerifyEmail
                personalInformation={personalInformation}
                handleNext={handleNext}
                sendEmailVerification={sendEmailVerification}
                sendEmailVerificationLoading={sendEmailVerificationLoading}
                sendEmailVerificationError={sendEmailVerificationError}
              />
            )
          },
          {
            label: translationFileReady ? t('physicianInstitution.title') : <StepperLabelSkeleton />,
            ariaLabel: t('physicianInstitution.accessibleTitle'),
            disableGoingForward: isEmptyArray(healthCareProvidersState.healthCareProviders),
            content: (
              <InstitutionPhysicianPicker
                translationFileReady={translationFileReady}
                t={t}
                healthCareProviders={healthCareProvidersState.healthCareProviders}
                dispatch={dispatch}
                allowNext={isEmptyArray(healthCareProvidersState.healthCareProviders)}
                handleNext={handleNext}
              />
            )
          },
          {
            label: translationFileReady ? t('authorization.title') : <StepperLabelSkeleton />,
            ariaLabel: t('personalInformation.accessibleTitle'),
            disableGoingForward: !isNotUndefined(signatureUrl) || !isConsentAuthorized,
            content: (
              <Authorization
                signatureUrl={signatureUrl}
                saveSignatureUrl={saveSignatureUrl}
                handleNext={handleNext}
                isConsentAuthorized={isConsentAuthorized}
                handleConsentAuthorized={handleConsentAuthorized}
              />
            )
          },
          {
            label: translationFileReady ? t('review.title') : <StepperLabelSkeleton />,
            ariaLabel: t('review.accessibleTitle'),
            content: (
              <ReviewSummary
                personalInformation={personalInformation}
                institutionData={healthCareProvidersState.healthCareProviders}
                signatureUrl={signatureUrl}
                confirmationTextId={confirmationTextId}
              />
            )
          }
        ]}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </div>
  );
};

export default NewRequestForm;
