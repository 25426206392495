import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { TFunction } from 'react-i18next';
import config from '../config';
import i18n from '../i18n';
import { isNotUndefined } from '../utils';

const CUSTOM_ERR_CODES = [
  // if any of these codes are present in an error response, return the whole error object
  // as it will have custom handling
  'NOT_INSTITUTION_USER',
  'EMAIL_ALREADY_INVITED',
  'NOT_EMAIL_VERIFIED',
  'NO_ACTIVE_SUBSCRIPTION'
];

type FallbackErrorMessageFunction = (t: TFunction<'errors'>) => string;

export class ApiService {
  _endpoint: string | undefined;

  _language: string | undefined;

  constructor(endpoint?: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  get token() {
    return window.localStorage.publicAccessToken;
  }

  get publicPortalToken() {
    return window.localStorage.publicPortalAccessToken;
  }

  get headers() {
    const headers: any = {
      'Content-Type': 'application/json'
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    if (this._language) {
      headers['Accept-Language'] = this._language;
    }

    return headers;
  }

  setLanguage(language: string) {
    this._language = language;
  }

  setEndpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  resetToken() {
    window.localStorage.removeItem('access_token');
  }

  handleError(error: AxiosError, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    const checkErrorCodeExistence = (code: string) => i18n.exists(`errors:errorCode.${code}`);
    const errorsTFunction: TFunction<'errors'> = i18n.getFixedT(i18n.language, 'errors');

    if (error.response && error.response.data) {
      if (error.response.data.error && error.response.data.error.code) {
        if (CUSTOM_ERR_CODES.includes(error.response.data.error.code)) {
          return Promise.reject(error.response.data.error);
        }

        const { code } = error.response.data.error;
        const translationKey = `errors:errorCode.${code}`;

        const doesErrorCodeTranslationExist = checkErrorCodeExistence(code);

        if (doesErrorCodeTranslationExist) {
          return Promise.reject(errorsTFunction(translationKey as any));
        }
        if (isNotUndefined(fallbackErrorMessage)) {
          return Promise.reject(fallbackErrorMessage(errorsTFunction));
        }
        return Promise.reject(errorsTFunction('api.generic'));
      }

      /**
       * This condition handles errors returned by auth service endpoints
       * as the error structure is different in auth service than the regular api
       */
      if (error.response.data.errors) {
        const { errors } = error.response.data;

        const translatedErrors = errors.map((err: any) => {
          const translationKey = `errors:errorCode.${err.code}`;

          if (err.message_code) {
            const translationKey = `passwordInput:messages.${err.message_code}`;
            const doesMessageCodeTransaltionExist = i18n.exists(translationKey);

            if (doesMessageCodeTransaltionExist) {
              const translatedMessage = i18n.t(translationKey);
              return { ...err, translatedMessage };
            }
          }

          const doesTranslationExist = checkErrorCodeExistence(err.code);

          if (doesTranslationExist) {
            return { ...err, translatedMessage: errorsTFunction(translationKey as any) };
          }
          return { ...err, translatedMessage: '' };
        });

        return Promise.reject(translatedErrors);
      }

      return Promise.reject(errorsTFunction('api.generic'));
    }
    if (error.response && error.response.status >= 500) {
      return Promise.reject(errorsTFunction('api.generic'));
    }
    if (isNotUndefined(fallbackErrorMessage)) {
      return Promise.reject(fallbackErrorMessage(errorsTFunction));
    }
    return Promise.reject(error.message || error);
  }

  get<T = any>(
    url: string,
    options: Omit<AxiosRequestConfig, 'method' | 'url' | 'headers'> = {},
    fallbackErrorMessage?: FallbackErrorMessageFunction
  ): Promise<T> {
    const opts: AxiosRequestConfig = {
      ...options,
      method: 'get',
      url: `${this.endpoint}${url}`,
      headers: this.headers
    };

    if (url.includes('/public/portal')) {
      opts.headers = { ...this.headers, Authorization: `Bearer ${this.publicPortalToken}` };
    }

    return axios(opts)
      .then((response) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  post<T = any>(
    url: string,
    body: any,
    headers?: AxiosRequestConfig['headers'],
    fallbackErrorMessage?: FallbackErrorMessageFunction,
    responseType?: 'json' | 'blob' | 'arraybuffer' | 'document' | 'stream'
  ) {
    const opts: AxiosRequestConfig = {
      url: `${this.endpoint}${url}`,
      method: 'post',
      data: JSON.stringify(body),
      headers: headers || this.headers,
      responseType: responseType || 'json'
    };

    if (!headers && url.includes('/public/portal')) {
      opts.headers = { ...this.headers, Authorization: `Bearer ${this.publicPortalToken}` };
    }

    return axios(opts)
      .then((response: AxiosResponse<T>) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  put(
    url: string,
    body: any,
    headers?: AxiosRequestConfig['headers'],
    fallbackErrorMessage?: FallbackErrorMessageFunction
  ) {
    const opts: AxiosRequestConfig = {
      url: `${this.endpoint}${url}`,
      method: 'put',
      data: JSON.stringify(body),
      headers: headers || this.headers
    };

    if (!headers && url.includes('/public/portal')) {
      opts.headers = { ...this.headers, Authorization: `Bearer ${this.publicPortalToken}` };
    }

    return axios(opts)
      .then((response) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  file(url: string, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    const opts: AxiosRequestConfig = {
      url: `${this.endpoint}${url}`,
      method: 'get',
      headers: this.headers,
      responseType: 'blob'
    };

    if (url.includes('/public/portal')) {
      opts.headers = { ...this.headers, Authorization: `Bearer ${this.publicPortalToken}` };
    }

    return axios(opts)
      .then((res) => res.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  upload(
    url: string,
    file?: File,
    fileField?: string,
    data = {} as any,
    method: Method = 'POST',
    fallbackErrorMessage?: FallbackErrorMessageFunction
  ) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      if (Array.isArray(file)) {
        file.forEach((f) => {
          formData.append(fileField!, f, f.name);
        });
      } else if (fileField && file) {
        formData.append(fileField, file, file.name);
      }

      const opts: AxiosRequestConfig = {
        method,
        url: `${this.endpoint}${url}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      if (url.includes('/public/portal')) {
        opts.headers = { ...this.headers, Authorization: `Bearer ${this.publicPortalToken}` };
      }

      axios(opts)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const errorsTFunction: TFunction<'errors'> = i18n.getFixedT(i18n.language, 'errors');
          if (error.response?.data?.code) {
            const checkErrorCodeExistence = (code: string) => i18n.exists(`errors:errorCode.${code}`);
            if (checkErrorCodeExistence(error.response.data.code)) {
              reject(errorsTFunction(`errorCode.${error.response.data.code}` as any));
            }
          }
          if (fallbackErrorMessage !== undefined) {
            reject(fallbackErrorMessage(errorsTFunction));
          }
          reject(errorsTFunction('api.generic'));
        });
    });
  }
}

const apiService = new ApiService(config.API_ENDPOINT);
export default apiService;
