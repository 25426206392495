import SignatureCanvas from 'react-signature-canvas';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './Icon';
import { useTranslate } from '../../hooks/useTranslate';
import Text from './Typography';
import Button from './Button';
import { isNotUndefined } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '13rem',
    [theme.breakpoints.up('xs')]: {
      height: '15rem'
    }
  },
  imageWrapper: {
    position: 'relative',
    width: '100%'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: 'auto'
  },
  canvas: {
    backgroundColor: theme.palette.catSkillWhite.main,
    width: '100%',
    height: '100%',
    borderRadius: '2rem'
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0'
  },
  signatureImg: {
    maxWidth: '23rem',
    height: '100%'
  }
}));

interface SignaturePadProps {
  signatureUrl: string | undefined;
  saveSignatureUrl: (url: string | undefined, isEmpty: boolean) => void;
}

const SignaturePad = ({ signatureUrl, saveSignatureUrl }: SignaturePadProps) => {
  const { t } = useTranslate('signaturePad');
  const classes = useStyles();

  const containerRef = useRef<any>(null);
  const signaturePadRef = useRef<any>(null);

  const [signingStarted, setSigningStarted] = useState(false);

  const removeSignatureNotice = () => {
    setSigningStarted(true);
  };

  const resetSignaturePad = () => {
    if (signaturePadRef && isNotUndefined(signaturePadRef.current.clear)) {
      signaturePadRef.current.clear();
      saveSignatureUrl(undefined, true);
      handleStrokeEnds();
    }
  };

  const handleStrokeEnds = () => {
    if (
      signaturePadRef &&
      isNotUndefined(signaturePadRef.current.getTrimmedCanvas) &&
      isNotUndefined(saveSignatureUrl)
    ) {
      const signatureUrl = signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
      const isEmpty = signaturePadRef.current.isEmpty();
      saveSignatureUrl(signatureUrl, isEmpty);
    }
  };

  return (
    <div
      className={clsx(!signingStarted && signatureUrl ? classes.imageWrapper : classes.container)}
      ref={containerRef}
    >
      {!signingStarted && !signatureUrl && (
        <Button
          isSecondary
          style={{
            background: 'transparent'
          }}
          className={classes.messageContainer}
          onClick={removeSignatureNotice}
        >
          <Icon size='x-large' name='signature' />
          <Text paragraph fontWeight={600} color='purple'>
            {t('signatureDescription')}
          </Text>
        </Button>
      )}
      {!signingStarted && signatureUrl ? (
        <div className={classes.imageContainer}>
          <img className={classes.signatureImg} src={signatureUrl} alt={t('signaturePadAlt')} />
        </div>
      ) : (
        <SignatureCanvas
          clearOnResize={false}
          onEnd={handleStrokeEnds}
          ref={signaturePadRef}
          onBegin={removeSignatureNotice}
          penColor='#672BC2'
          canvasProps={{ className: classes.canvas }}
        />
      )}
      {!signingStarted && signatureUrl ? (
        <Button
          isSecondary
          style={{
            width: 'fit-content',
            position: 'absolute',
            bottom: '0',
            right: '0',
            border: 'none',
            background: 'transparent',
            color: '#672bc2'
          }}
          label={t('reset')}
          onClick={() => {
            saveSignatureUrl('', true);
            removeSignatureNotice();
          }}
        />
      ) : (
        <>
          {signingStarted && (
            <Button
              isSecondary
              style={{
                width: 'fit-content',
                position: 'absolute',
                bottom: '0',
                right: '0',
                border: 'none',
                background: 'transparent',
                color: '#672bc2'
              }}
              label={t('reset')}
              onClick={resetSignaturePad}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SignaturePad;
