import moment from 'moment';

export const PARTNERED_INSTITUTIONS = {
  teladoc: 'Teladoc'
};

export const LOCALSTORAGE = {
  publicVerifyEmailToken: 'publicVerifyEmailToken',
  publicAccessToken: 'publicAccessToken'
};

export const DOT_HEALTH_PRIVACY_POLICY_URL = 'https://www.dothealth.ca/privacy-policy';
export const DOT_HEALTH_TERMS_URL = 'https://www.dothealth.ca/terms';

export const MIN_CLIENT_BIRTH_DATE = moment().subtract(18, 'years').toDate();
export const MAX_CLIENT_BIRTH_DATE = moment().subtract(120, 'years').toDate();
