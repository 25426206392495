import { BrowserRouter, Switch, useLocation, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './theme/primaryTheme';
import { SnackbarProvider } from './contexts/snackbar-context';
import { useTranslate } from './hooks/useTranslate';
import Spinner from './components/shared/Spinner';
import { useRoutes } from './hooks/useRoutes';
import Welcome from './components/pages/Welcome';
import RequestForm from './components/pages/RequestForm';
import { AuthProvider } from './contexts/auth-context';
import PrivateRoute from './components/shared/PrivateRoute';
import GenericErrorScreen from './components/shared/GenericErrorScreen';
import RequestSuccess from './components/pages/RequestSuccess';

const App = () => {
  const location = useLocation();
  const { routesTFunction } = useRoutes();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider>
            <div className='main'>
              <Switch location={location.state?.background || location}>
                <PrivateRoute exact path={routesTFunction('routerPaths./')} component={Welcome} />
                <PrivateRoute exact path={routesTFunction('routerPaths./request/new')} component={RequestForm} />
                <PrivateRoute
                  exact
                  path={routesTFunction('routerPaths./request/successful')}
                  component={RequestSuccess}
                />
                <Route path='*' component={() => <GenericErrorScreen is404 />} />
              </Switch>
            </div>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1, refetchOnWindowFocus: false } } });

const DefaultApp = () => {
  const { ready, isInitialized } = useTranslate(['routes', 'errors']);

  if (!ready || !isInitialized) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default DefaultApp;
