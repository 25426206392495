import clsx from 'clsx';
import React from 'react';

interface ButtonTextProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  className?: string;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  isPrimary?: boolean;
  isDanger?: boolean;
  span?: boolean;
}

const ButtonText = ({
  isPrimary,
  isDanger,
  className,
  label,
  span,
  onClick,
  isDisabled,
  style,
  children,
  ...other
}: ButtonTextProps) => {
  const classes = clsx('btn', 'is-link-button', isPrimary && 'color-primary', isDanger && 'color-danger', className);

  const returnText = () => {
    if (label) {
      return label;
    }

    return children;
  };

  if (span) {
    return (
      <span className={classes} style={style} disabled={isDisabled} onClick={onClick} type='button' {...other}>
        {returnText()}
      </span>
    );
  }

  return (
    <button className={classes} style={style} disabled={isDisabled} onClick={onClick} type='button' {...other}>
      {returnText()}
    </button>
  );
};

export default ButtonText;
