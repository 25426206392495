import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MUIStepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Divider from './Divider';
import Icon from './Icon';
import { conditionalProps, generateKey, hexToRGB } from '../../utils';

const StepConnector = withStyles({
  alternativeLabel: {
    top: 19
  },
  line: {
    borderColor: '#eaeaf0',
    margin: '0 0.5rem',
    height: 3,
    borderRadius: 1
  }
})(MUIStepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  mobileStepper: {
    background: 'none',
    padding: '2.4rem 0rem 1.2rem 0rem',
    '& .MuiMobileStepper-dot': {
      width: '1.2rem',
      height: '1.2rem',
      margin: '0 1rem'
    },
    '& .MuiButton-text': {
      fontSize: '1.4rem'
    }
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  iconStyles: {
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    cursor: 'not-allowed',
    '&[role="button"]': {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0rem 0rem 0.4rem 0.2rem ${hexToRGB(theme.palette.primary.hover!, 0.47)};`
      }
    },
    alignItems: 'center',
    '&>i, &>svg': {
      height: 40,
      width: 40
    }
  },
  stepTitle: {
    color: theme.palette.text.main,
    width: '100%'
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface StepIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  active?: boolean;
  completed?: boolean;
}

function StepIcon(props: StepIconProps) {
  const { active, completed, role, ...others } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.iconStyles)} role={role} {...others}>
      {active ? (
        <Icon name='radio-check-primary' />
      ) : completed ? (
        <Icon name='check-circle-green' />
      ) : (
        <Icon name='circle-grey' />
      )}
    </div>
  );
}

interface Step {
  content: React.ReactNode;
  label: string | React.ReactNode;
  disableGoingForward?: boolean;
  ariaLabel?: string;
}

export interface StepperProps {
  steps: Step[];
  activeStep: number;
  setActiveStep: any;
  disabledSteps?: number[];
}

export default function CustomStepper({ steps, activeStep, setActiveStep, disabledSteps }: StepperProps) {
  const classes = useStyles();
  const isTabletPlus = useMediaQuery('(min-width:650px)');

  const getStepContent = (step: number) => {
    if (!steps[step]) return 'Undefined step';
    return steps[step].content;
  };

  const canStepMoveToGivenIndex = (index: number) => {
    if (index < activeStep) return true;
    let canMoveForward = true;
    for (let i = 0; i < index; i++) {
      const { disableGoingForward } = steps[i];
      if (disableGoingForward) {
        canMoveForward = false;
        break;
      }
    }
    return canMoveForward;
  };

  const handleSetActiveStep = (index: number) => {
    if (canStepMoveToGivenIndex(index)) {
      setActiveStep(index);
    }
  };

  const handleNext = () => {
    setActiveStep((currentActiveStep: number) => currentActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((currentActiveStep: number) => {
      // If a previous step is disabled find a step we can go back to, if none is found we stay in the step
      if (disabledSteps?.includes(currentActiveStep - 1)) {
        for (let i = currentActiveStep - 1; i >= 0; i--) {
          if (!disabledSteps?.includes(i)) {
            return i;
          }
        }
        return currentActiveStep;
      }
      return currentActiveStep - 1;
    });
  };

  return (
    <div className={classes.root}>
      {isTabletPlus ? (
        <Stepper style={{ background: 'none' }} alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
          {steps.map((step, index) => {
            return (
              <MUIStep key={generateKey('stepper-step', index)}>
                <StepLabel
                  StepIconComponent={(props) => {
                    const stepDisabled = () => {
                      if (disabledSteps && disabledSteps.includes(index)) {
                        return true;
                      }

                      return false;
                    };

                    return (
                      <StepIcon
                        {...props}
                        {...conditionalProps(
                          {
                            onClick: () => {
                              handleSetActiveStep(index);
                            },
                            tabIndex: 0,
                            role: 'button',
                            'aria-disabled': !canStepMoveToGivenIndex(index) && !stepDisabled(),
                            'aria-label': step.ariaLabel,
                            onKeyPress: (e) => {
                              const { code } = e;
                              if (code === 'Space' || code === 'Enter') {
                                handleSetActiveStep(index);
                              }
                            }
                          },
                          canStepMoveToGivenIndex(index) && !stepDisabled()
                        )}
                      />
                    );
                  }}
                >
                  <div className={classes.stepTitle}>{step.label}</div>
                </StepLabel>
              </MUIStep>
            );
          })}
        </Stepper>
      ) : (
        <MobileStepper
          className={classes.mobileStepper}
          variant='dots'
          steps={steps.length}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button
              variant='text'
              onClick={handleNext}
              disabled={activeStep === steps.length - 1 || !canStepMoveToGivenIndex(activeStep + 1)}
            >
              Next
            </Button>
          }
          backButton={
            <Button
              variant='text'
              onClick={handleBack}
              disabled={activeStep === 0 || !canStepMoveToGivenIndex(activeStep - 1)}
            >
              Back
            </Button>
          }
        />
      )}
      <Divider />

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </div>
  );
}
