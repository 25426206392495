import { useState } from 'react';
import { InstitutionAddress, Physician } from '../../../../types';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import CreatePhysicianModal from '../../../shared/create-physician-modal';
import PhysicianPicker from '../../../shared/PhysicianPicker';
import { isNotUndefined } from '../../../../utils';
import { useTranslate } from '../../../../hooks/useTranslate';

interface PhysicianInputProps {
  institutionAddress: InstitutionAddress;
  address_id: number;
  onPhysicianSelect: (physician: Physician) => void;
  onOutsideClick?: () => void;
}

const PhysicianInput = ({ institutionAddress, address_id, onPhysicianSelect, onOutsideClick }: PhysicianInputProps) => {
  const { t } = useTranslate('requestForm');

  const [openPhysicianModal, setOpenPhysicianModal] = useState(false);
  const { ref } = useOutsideClick<HTMLDivElement>(() => {
    if (!openPhysicianModal && isNotUndefined(onOutsideClick)) {
      onOutsideClick();
    }
  });

  const showPhysicianModal = () => {
    setOpenPhysicianModal(true);
  };

  const closePhysicianModal = () => {
    setOpenPhysicianModal(false);
  };

  return (
    <>
      {openPhysicianModal && (
        <CreatePhysicianModal
          open={openPhysicianModal}
          onClose={closePhysicianModal}
          setPhysician={onPhysicianSelect}
          address_id={address_id}
          institutionAddress={institutionAddress}
        />
      )}
      <div ref={ref}>
        <PhysicianPicker
          addNewPhysicianButtonText={t('physicianAndInstitutionPicker.physicianInput.addNewPhysicianButtonText')}
          maxWidth
          institutionAddress={institutionAddress}
          onSelect={onPhysicianSelect}
          onShowModal={showPhysicianModal}
        />
      </div>
    </>
  );
};

export default PhysicianInput;
