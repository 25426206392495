import { IconNames } from '../../types/common/IconTypes';
import Icon from './Icon';

const getIconName = (partnerName: string) => {
  switch (partnerName) {
    case 'Dot Health':
    case 'Dot QA':
    case 'Dot Health Clinic (QA)':
      return 'dot-health-icon-circle';
    default:
      return partnerName.toLowerCase().replace(/\s+/g, '-');
  }
};

export interface PartnerLogoProps {
  partnerName: string;
}

const PartnerLogo = ({ partnerName }: PartnerLogoProps) => {
  const iconName = getIconName(String(partnerName).toLowerCase()) as IconNames;

  if (!iconName) return null;
  return <Icon size='large' name={iconName} />;
};

export default PartnerLogo;
