import { makeStyles } from '@material-ui/core';
import { Root, Indicator, CheckboxProps as PrimitiveCheckboxProps } from '@radix-ui/react-checkbox';
import clsx from 'clsx';
import { hexToRGB } from '../../utils';
import useId from '../../hooks/useId';
import CheckIcon from './icons/CheckIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  indicator: {
    height: '16px',
    width: '16px',
    color: 'white'
  },
  checkBoxRoot: {
    backgroundColor: 'white',
    minWidth: '16px',
    minHeight: '16px',
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: 4,
    margin: '1rem',
    border: `1px solid ${theme.palette.shipCove.main}`,
    '&:focus,&:hover': {
      boxShadow: `0 0 0 10px ${hexToRGB(theme.palette.primary.main, 0.1)}`
    },
    padding: 0
  },
  checkIcon: {
    height: '16px',
    width: '16px'
  }
}));

export interface CheckboxProps extends PrimitiveCheckboxProps {
  label?: string | JSX.Element;
  checked: boolean;
  labelClassname?: string;
  containerClassName?: string;
}

const Checkbox = ({
  label,
  className,
  containerClassName,
  checked,
  onCheckedChange,
  labelClassname,
  ...other
}: CheckboxProps) => {
  const classes = useStyles();

  const id = useId({ defaultValue: other.id, prefix: 'checkbox' });

  return (
    <div className={clsx(classes.container, containerClassName)}>
      <Root
        id={id}
        checked={checked}
        className={clsx(classes.checkBoxRoot, className)}
        onCheckedChange={onCheckedChange}
        {...other}
      >
        <Indicator className={classes.indicator}>
          <CheckIcon className={classes.checkIcon} color={(theme) => theme.palette.primary.main} />
        </Indicator>
      </Root>
      <label className={labelClassname} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
