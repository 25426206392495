import { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, useMediaQuery } from '@material-ui/core/';
import { hexToRGB, provinces } from '../../../../../utils';
import { useTranslate } from '../../../../../hooks/useTranslate';
import DropdownInput from '../../../../shared/DropdownInput';
import Input from '../../../../shared/Input';
import Skeleton from '../../../../shared/Skeleton';
import Container from '../../../../shared/Container';
import { GetFieldPropsFunction } from '../../../../../hooks/useForm';
import { CREATE_INSTITUTION_INITIAL_VALUES } from '.';
import Text from '../../../../shared/Typography';
import Button from '../../../../shared/Button';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    padding: '4rem 0',
    maxWidth: '54rem'
  },
  sectionContainer: {
    minHeight: '4.6rem',
    width: '100%',
    padding: '0 2.7rem',
    marginBottom: '3rem'
  },
  inputRoot: {
    marginTop: 0,
    marginLeft: 0
  },
  inputLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    color: theme.palette.grayscale['60'],
    fontWeight: 300
  },
  inputRow: {
    position: 'relative'
  },
  inputColumn: {
    position: 'relative',
    width: '33%'
  },
  input: {
    fontWeight: 500,
    width: '100%',
    padding: '1rem 2rem',
    borderRadius: '3.6rem',
    margin: 0,
    border: `0.2rem solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      border: `0.2rem  solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      fontWeight: 500,
      border: 'none',
      textAlign: 'left'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    },
    '&[aria-invalid=true]': {
      color: theme.palette.error.main,
      border: `0.2rem solid ${theme.palette.error.main}`
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  addressInputGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    margin: '0 auto',
    minWidth: '1.6rem',
    minHeight: '5rem'
  }
}));

interface CreateInstitutionFormUIProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  getFieldProps: GetFieldPropsFunction<typeof CREATE_INSTITUTION_INITIAL_VALUES>;
  submitError?: string;
  submitLoading?: boolean;
}

const LabelSkeleton = () => <Skeleton height='2.5rem' width='25rem' />;

const provincesForDropDown = ['', ...provinces];

const CreateInstitutionFormUI = ({
  onSubmit,
  getFieldProps,
  submitError,
  submitLoading
}: CreateInstitutionFormUIProps) => {
  const classes = useStyles();
  const isMobilePlus = useMediaQuery('(min-width:425px)');

  const { t: provinceTFunction, ready: isProvinceTranslationReady } = useTranslate('provinces');
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const mappedProvinces = useMemo(() => {
    if (isProvinceTranslationReady) {
      return provincesForDropDown.map((province) => {
        if (!province) return { label: '', value: '' };
        return {
          value: province,
          label: provinceTFunction(`${province}` as any)
        };
      });
    }
    return [{ label: '', value: '' }];
  }, [provinceTFunction, isProvinceTranslationReady]);

  const defaultInputProps = {
    wrapperProps: { className: classes.inputRoot },
    className: clsx(classes.input),
    maxWidth: true
  };

  return (
    <form onSubmit={onSubmit} className={classes.formContainer}>
      <Container className={classes.sectionContainer}>
        <Input
          {...defaultInputProps}
          label={
            isRequestFormTranslationReady ? (
              requestFormTFunction('physicianAndInstitutionPicker.createInstitution.institutionName')
            ) : (
              <LabelSkeleton />
            )
          }
          {...getFieldProps('institution_name')}
          noPlaceholders
        />
        <Input
          {...defaultInputProps}
          label={
            isRequestFormTranslationReady ? (
              requestFormTFunction('physicianAndInstitutionPicker.createInstitution.phone')
            ) : (
              <LabelSkeleton />
            )
          }
          {...getFieldProps('phone')}
          noPlaceholders
        />
        <Input
          {...defaultInputProps}
          label={
            isRequestFormTranslationReady ? (
              requestFormTFunction('physicianAndInstitutionPicker.createInstitution.fax')
            ) : (
              <LabelSkeleton />
            )
          }
          {...getFieldProps('fax')}
          noPlaceholders
        />
        <label htmlFor={getFieldProps('address').name} className={clsx(classes.inputLabel)}>
          {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.address') : <LabelSkeleton />}
        </label>
        <Input
          {...defaultInputProps}
          {...getFieldProps('address')}
          placeholder={
            isRequestFormTranslationReady
              ? requestFormTFunction('physicianAndInstitutionPicker.createInstitution.street')
              : undefined
          }
        />
        <Input
          {...defaultInputProps}
          {...getFieldProps('name')}
          placeholder={
            isRequestFormTranslationReady
              ? requestFormTFunction('physicianAndInstitutionPicker.createInstitution.addressName')
              : undefined
          }
        />
        {isMobilePlus ? (
          <>
            <div className={`${classes.addressInputGroup}`}>
              <div style={{ width: '49%', position: 'relative' }}>
                <Input
                  {...defaultInputProps}
                  {...getFieldProps('city')}
                  placeholder={
                    isRequestFormTranslationReady ? requestFormTFunction('personalInformation.city') : undefined
                  }
                />
              </div>
              <div style={{ width: '49%', position: 'relative' }}>
                <DropdownInput
                  {...defaultInputProps}
                  {...getFieldProps<HTMLSelectElement>('province')}
                  options={mappedProvinces}
                  placeholderText={
                    isRequestFormTranslationReady ? requestFormTFunction('personalInformation.province') : undefined
                  }
                  noLabel
                />
              </div>
            </div>
            <div style={{ width: '49%', position: 'relative' }}>
              <Input
                {...defaultInputProps}
                {...getFieldProps('postal_code')}
                placeholder={
                  isRequestFormTranslationReady ? requestFormTFunction('personalInformation.postalCode') : undefined
                }
              />
            </div>
          </>
        ) : (
          <>
            <Input
              {...defaultInputProps}
              {...getFieldProps('city')}
              placeholder={isRequestFormTranslationReady ? requestFormTFunction('personalInformation.city') : undefined}
            />
            <Input
              {...defaultInputProps}
              {...getFieldProps('postal_code')}
              placeholder={
                isRequestFormTranslationReady ? requestFormTFunction('personalInformation.postalCode') : undefined
              }
            />
            <DropdownInput
              {...defaultInputProps}
              {...getFieldProps<HTMLSelectElement>('province')}
              options={mappedProvinces}
              placeholderText={
                isRequestFormTranslationReady ? requestFormTFunction('personalInformation.province') : undefined
              }
              noLabel
            />
          </>
        )}
      </Container>
      {submitError && (
        <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
          {submitError}
        </Text>
      )}
      <Container className={classes.buttonContainer}>
        {isRequestFormTranslationReady ? (
          <Button
            style={{ minWidth: '24rem' }}
            isLoading={submitLoading}
            type='submit'
            label={
              submitLoading
                ? ''
                : requestFormTFunction('physicianAndInstitutionPicker.createInstitution.createInstitution')
            }
          />
        ) : (
          <Skeleton width='10rem' height='4.6rem' />
        )}
      </Container>
    </form>
  );
};

export default CreateInstitutionFormUI;
