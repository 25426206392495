import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { routesENCA } from './routes';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    whitelist: ['en-CA', 'fr-CA'],
    fallbackLng: 'en-CA',
    debug: false,
    load: 'currentOnly', // Load only the current language translation files
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false
    }
  });

/**
 * Adding the routes resource bundle directly to have them ready whenever the app is loaded
 */
i18n.addResourceBundle('en-CA', 'routes', routesENCA);

export default i18n;
