import clsx from 'clsx';
import { PartialLocation } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconNames } from '../../types/common/IconTypes';
import Icon from './Icon';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode;
  formButton?: boolean;
  urlTo?: string | PartialLocation;
  iconName?: IconNames;
  iconComponent?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  isComplete?: string;
  filterButton?: boolean;
  selected?: boolean;
  isSecondary?: boolean;
  iconRight?: boolean;
  isSequential?: boolean;
  isWaiting?: boolean;
  isProcessing?: boolean;
  isBlock?: boolean;
  iconOnly?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((buttonProps, ref) => {
  const {
    label,
    formButton,
    urlTo,
    iconName,
    iconComponent: PropIcon,
    className: PropClassName,
    isLoading,
    isDisabled,
    isComplete,
    filterButton,
    selected,
    isSecondary,
    iconRight,
    isSequential,
    isWaiting,
    isProcessing,
    isBlock,
    iconOnly,
    id,
    ...other
  } = buttonProps;

  const ButtonIcon = <Icon name={iconName} />;

  const className = clsx(
    'btn',
    isLoading && 'is-loading',
    isDisabled && 'is-disabled-opacity',
    isComplete && 'is-complete',
    !PropClassName && isSecondary && 'is-secondary',
    !PropClassName && isWaiting && 'is-waiting',
    !PropClassName && !isSecondary && !isWaiting && 'is-primary',
    isSequential && 'is-sequential',
    isBlock && 'is-block',
    !PropClassName && isProcessing && 'is-processing',
    iconOnly && 'icon-only',
    PropClassName
  );

  if (filterButton) {
    return (
      <button
        ref={ref}
        style={selected ? { border: '0.1rem solid transparent', color: '#fff' } : {}}
        className={className}
        type='submit'
        id={id}
        {...other}
      >
        <span>{label}</span>
        {selected && <i className='icon is-x-small ic-close' />}
      </button>
    );
  }
  if (formButton) {
    return (
      <button ref={ref} className={className} type='submit' id={id} {...other}>
        {isLoading ? <i className='spin' style={{ marginRight: 0 }} /> : <span>{label}</span>}
      </button>
    );
  }
  if (urlTo) {
    return (
      <Link
        {...(isDisabled
          ? {
              onClick: (e) => {
                e.preventDefault();
              },
              style: { cursor: 'not-allowed' }
            }
          : {})}
        ref={ref as any}
        {...(other as any)}
        className={className}
        to={urlTo}
        id={id || ''}
        rel='noopener noreferrer'
      >
        {!isLoading && !iconRight && (PropIcon || (iconName && ButtonIcon))}
        {label && <span>{label}</span>}
        {!isLoading && iconRight && (PropIcon || (iconName && ButtonIcon))}
      </Link>
    );
  }
  return (
    <button ref={ref} className={className} id={id || ''} disabled={isDisabled || isLoading} type='button' {...other}>
      {isLoading && <i className='spin' />}
      {!isLoading && !iconRight && (PropIcon || (iconName && ButtonIcon))}
      {label && <span>{label}</span>}
      {buttonProps.children && buttonProps.children}
      {!isLoading && iconRight && (PropIcon || (iconName && ButtonIcon))}
    </button>
  );
});

export default Button;
