import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import Input from './Input';
import useOutsideClick from '../../hooks/useOutsideClick';
import Divider from './Divider';
import { InstitutionAddress, Physician } from '../../types';
import ButtonText from './ButtonText';
import { useDebounce } from '../../hooks/useDebounce';
import { isBlankString } from '../../utils';
import useQuery from '../../hooks/useQuery';
import Text from './Typography';
import Spinner from './Spinner';
import { useTranslate } from '../../hooks/useTranslate';

export interface PhysicianPickerProps {
  institutionAddress: InstitutionAddress;
  onSelect: (physician: Physician) => void;
  addNewPhysicianButtonText?: string;
  hidden?: boolean;
  maxWidth?: boolean;
  label?: string;
  selectUser?: boolean;
  onShowModal: () => void;
}

interface Active {
  physician: Physician;
  index: number;
}

const PhysicianPicker = ({
  institutionAddress,
  onSelect,
  hidden,
  maxWidth,
  selectUser,
  label,
  onShowModal,
  addNewPhysicianButtonText
}: PhysicianPickerProps) => {
  const { t } = useTranslate('requestForm');

  const [physicians, setPhysicians] = useState<Physician[]>([]);
  const [active, setActive] = useState<Active | null>();
  const [query, setQuery] = useState('');
  const debounceQuery = useDebounce(
    useMemo(() => (isBlankString(query) ? '' : query), [query]),
    200
  );

  const { ref } = useOutsideClick<HTMLDivElement>(() => {
    setQuery('');
    setActive(null);
  });

  const { loading, makeRequest } = useQuery(
    `/institution_addresses/${institutionAddress.id}/physicians?search=${debounceQuery}`,
    'GET'
  );

  useEffect(() => {
    if (debounceQuery && debounceQuery.length > 0) {
      if (institutionAddress && institutionAddress.id) {
        makeRequest().then((req) => setPhysicians(req.physicians));
      }
    } else {
      setPhysicians([]);
    }
  }, [debounceQuery, institutionAddress, makeRequest]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const prev = () => {
    const lastPhysicianIndex = physicians.length - 1;
    if (!active?.physician) {
      setActive({
        physician: {
          ...physicians[lastPhysicianIndex]
        },
        index: lastPhysicianIndex
      });
    } else if (active.index === 0) {
      setActive(null);
    } else {
      setActive((prevValues) => {
        return {
          physician: {
            ...physicians[prevValues!.index - 1]
          },
          index: prevValues!.index - 1
        };
      });
    }
  };

  const next = () => {
    const lastPhysicianIndex = physicians.length - 1;
    if (!active?.physician) {
      setActive({
        physician: { ...physicians[0] },
        index: 0
      });
    } else if (active.index === lastPhysicianIndex) {
      setActive(null);
    } else {
      setActive((prevValues) => ({
        physician: { ...physicians[prevValues!.index + 1] },
        index: prevValues!.index + 1
      }));
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = e;
    if (!physicians || !(physicians.length > 0)) {
      return;
    }
    if (keyCode === 38) {
      prev();
    } else if (keyCode === 40) {
      next();
    } else if (keyCode === 13) {
      if (active?.physician) {
        setQuery('');
        setPhysicians([]);
        setActive(null);
        onSelect(active.physician);
      }
    } else {
      setActive(null);
    }
  };

  const isSelected = (physician: Physician) => {
    return active && active.physician && active.physician.id === physician.id;
  };

  const selectPhysician = (physician: Physician) => {
    onSelect(physician);
    setQuery('');
  };

  if (hidden) return null;
  return (
    <div ref={ref}>
      <div className={`dropdown w-100 ${physicians.length ? 'is-active' : ''}`}>
        <Input
          required
          focusOnMount
          autoComplete='off'
          maxWidth={maxWidth}
          value={query}
          onChange={onSearch}
          label={label}
          className='is-rounded center'
          onKeyDown={onKeyDown}
        />
        {!isBlankString(debounceQuery) && (
          <div className='dropdown-menu'>
            <div className='dropdown-content'>
              {loading ? (
                <Text paragraph style={{ padding: '21px 36px' }}>
                  <Spinner size='small' />
                </Text>
              ) : (
                <>
                  {physicians && physicians.length > 0 ? (
                    physicians.map((physician, physicianIndex) => (
                      <div key={physician.id}>
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div
                          className={clsx(
                            'dropdown-item',
                            isSelected(physician) && 'selected',
                            selectUser ? 'pointer dim' : ''
                          )}
                          onMouseEnter={() => setActive({ physician, index: physicianIndex })}
                          onMouseLeave={() => setActive(null)}
                          onClick={() => selectPhysician(physician)}
                        >
                          <div className='flex-1'>{`${physician.first_name} ${physician.last_name}`}</div>
                        </div>
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Text paragraph color='grey-60' style={{ padding: '21px 36px' }}>
                      {t('physicianAndInstitutionPicker.errors.noResultsFound', { searchQuery: query })}
                    </Text>
                  )}
                </>
              )}
              <div className='dropdown-item'>
                <ButtonText
                  isPrimary
                  label={addNewPhysicianButtonText}
                  onClick={() => {
                    onShowModal();
                    setQuery('');
                    setActive(null);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhysicianPicker;
