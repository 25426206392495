import { useMemo } from 'react';
import { generateRandomId } from '../utils';

interface UseIdOptions {
  prefix?: string;
  defaultValue?: string;
}

const useId = (options?: UseIdOptions) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const id = useMemo(() => generateRandomId(options?.prefix), [options?.prefix]);

  if (options?.defaultValue) {
    return options.defaultValue;
  }

  return id;
};

export default useId;
