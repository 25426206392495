/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface CheckIconProps extends SVGBaseProps {}

const CheckIcon = (props: CheckIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M19.3333 1H4.66667C2.64162 1 1 2.64162 1 4.66667V19.3333C1 21.3584 2.64162 23 4.66667 23H19.3333C21.3584 23 23 21.3584 23 19.3333V4.66667C23 2.64162 21.3584 1 19.3333 1Z'
        fill='inherit'
        stroke='inherit'
        strokeWidth='2'
      />
      <path
        d='M18.8466 8.33064C18.3754 7.88978 17.6112 7.88978 17.14 8.33064L9.4083 15.5635L6.85998 13.1797C6.38873 12.7387 5.62468 12.7387 5.15343 13.1797C4.68217 13.6205 4.68217 14.3352 5.15343 14.7761L8.54927 17.9528C9.0237 18.3967 9.7929 18.3967 10.2673 17.9528L18.8466 9.9271C19.3178 9.48624 19.3178 8.77148 18.8466 8.33064Z'
        fill='white'
      />
    </SVGBase>
  );
};

export default CheckIcon;
