import moment from 'moment';
import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitFunction, useForm, ValidationErrors } from '../../../../hooks/useForm';
import Container from '../../../shared/Container';
import {
  EXAMPLE_HCNS,
  isValidEmail,
  isValidHCN,
  isValidPhoneNumber,
  isValidPostalCode,
  Provinces
} from '../../../../utils';
import PageContainer from '../../../shared/PageContainer';
import Text from '../../../shared/Typography';
import Skeleton from '../../../shared/Skeleton';
import { useTranslate } from '../../../../hooks/useTranslate';
import PersonalInformationFormUI from './PersonalInformationFormUI';
import { PersonalInformationType } from '../../../../types';
import { MAX_CLIENT_BIRTH_DATE, MIN_CLIENT_BIRTH_DATE } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },
  pageContainer: {
    marginTop: '4rem'
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  titleSkeleton: {
    height: '3.2rem',
    width: '30rem'
  },
  descriptionSkeleton: {
    width: '27rem',
    height: '4.8rem',
    [theme.breakpoints.up('xs')]: {
      width: '46rem',
      height: '2.4rem'
    }
  }
}));

export const initialPersonalInformationState = {
  first_name: '',
  last_name: '',
  email: '',
  insurance_number: '',
  dob: '',
  phone_number: '',
  street_address: '',
  city: '',
  province: '',
  postal_code: '',
  extra_line_1: ''
};

interface PersonalInformationProps {
  personalInformation?: PersonalInformationType;
  onSubmit: (values: PersonalInformationType) => void;
  sendEmailVerificationError: string;
  sendEmailVerificationLoading: boolean;
}

const PersonalInformation = ({
  personalInformation,
  onSubmit: onSubmitCallback,
  sendEmailVerificationError,
  sendEmailVerificationLoading
}: PersonalInformationProps) => {
  const classes = useStyles();

  const { t: formErrorsT } = useTranslate('formErrors');
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const { values, getFieldProps, handleSubmit, validateField, resetErrors, setValue } = useForm(
    personalInformation || initialPersonalInformationState,
    {
      validate: (values) => {
        const errors: ValidationErrors<PersonalInformationType> = {};

        if (!values.first_name) {
          errors.first_name = formErrorsT('invalidFirstName');
        }
        if (!values.last_name) {
          errors.last_name = formErrorsT('invalidLastName');
        }
        if (!isValidEmail(values.email)) {
          errors.email = formErrorsT('invalidEmail');
        }
        if (!values.insurance_number || !isValidHCN(values.insurance_number, values.province as Provinces)) {
          if (values.province) {
            errors.insurance_number = formErrorsT('invalidHCNWithExample', { example: EXAMPLE_HCNS[values.province] });
          } else {
            errors.insurance_number = formErrorsT('invalidHCN');
          }
        }
        if (!values.dob) {
          errors.dob = formErrorsT('invalidDOB');
        }
        if (values.dob && moment(values.dob).isAfter(MIN_CLIENT_BIRTH_DATE)) {
          errors.dob = formErrorsT('invalidDOB_MIN');
        }
        if (values.dob && moment(values.dob).isBefore(MAX_CLIENT_BIRTH_DATE)) {
          errors.dob = formErrorsT('invalidDOB');
        }
        if (!values.phone_number || !isValidPhoneNumber(values.phone_number)) {
          errors.phone_number = formErrorsT('invalidPhoneNumber');
        }
        if (!values.street_address) {
          errors.street_address = formErrorsT('invalidStreetAddress');
        }
        if (!values.postal_code || !isValidPostalCode(values.postal_code)) {
          errors.postal_code = formErrorsT('invalidPostalCode');
        }
        if (!values.province) {
          errors.province = formErrorsT('invalidProvince');
        }
        if (!values.city) {
          errors.city = formErrorsT('invalidCity');
        }
        return errors;
      }
    }
  );

  const onSubmit: SubmitFunction<PersonalInformationType> = useCallback(
    (values: PersonalInformationType) => {
      onSubmitCallback(values);
      resetErrors();
    },
    [onSubmitCallback, resetErrors]
  );

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('personalInformation.title')
          ) : (
            <Skeleton className={classes.titleSkeleton} />
          )}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('personalInformation.description')
          ) : (
            <Skeleton className={classes.descriptionSkeleton} />
          )}
        </Text>
      </div>
      <PageContainer className={classes.pageContainer}>
        <PersonalInformationFormUI
          onSubmit={handleSubmit(onSubmit)}
          getFieldProps={getFieldProps}
          submitError={sendEmailVerificationError}
          submitLoading={sendEmailVerificationLoading}
          validateField={validateField}
          values={values}
          setValue={setValue}
        />
      </PageContainer>
    </Container>
  );
};

export default PersonalInformation;
