import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Institution, InstitutionAddress, Physician } from '../../../../types';
import ButtonText from '../../../shared/ButtonText';
import Card from '../../../shared/Card';
import Text from '../../../shared/Typography';
import { CloseIcon } from '../../../shared/icons';
import IconButton from '../../../shared/IconButton';
import PhysicianInput from './PhysicianInput';
import Input from '../../../shared/Input';
import { useTranslate } from '../../../../hooks/useTranslate';

interface InsitutitonCardProps {
  notes?: string;
  institution: Institution;
  institutionAddress: InstitutionAddress;
  physician?: Physician;
  removeHealthCareProvider: () => void;
  removePhysician: () => void;
  selectPhysicianForInstitution: (physician: Physician) => void;
  setNotesForRequest: (notes: string) => void;
  removeNotes: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    margin: '0 1rem'
  },
  cardDetailsContainer: {
    position: 'relative',
    marginBottom: '2rem'
  },
  inputContainer: {
    marginBottom: '1rem'
  },
  removeCardButton: {
    '&:focus,&:hover': {
      '& svg': {
        color: theme.palette.error.main
      }
    },
    position: 'absolute',
    top: '0.2rem',
    right: '0'
  },
  addressName: {
    lineHeight: '2rem',
    maxWidth: '80%'
  },
  editButton: {
    background: 'none'
  },
  editButtonText: {
    ...theme.typography.xxxSmall,
    color: theme.palette.shipCove.main,
    textDecoration: 'underline'
  },
  buttonContainer: {
    width: '100%',
    maxWidth: '15.8rem',
    [theme.breakpoints.up('xs')]: {
      margin: '0 auto'
    }
  }
}));

const InstitutionCard = ({
  institutionAddress,
  physician,
  notes,
  removeHealthCareProvider,
  removePhysician,
  selectPhysicianForInstitution,
  setNotesForRequest
}: InsitutitonCardProps) => {
  const classes = useStyles();
  const { t } = useTranslate('requestForm');

  const [showPhysicanPicker, setShowPhysicianPicker] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);

  return (
    <Card key={institutionAddress.id} className='create-request-item'>
      <div className={classes.cardDetailsContainer}>
        {institutionAddress && (
          <Text h6 fontWeight={500} color='purple' className={classes.addressName}>
            {institutionAddress.name}
          </Text>
        )}
        <IconButton
          onClick={removeHealthCareProvider}
          label={t('physicianAndInstitutionPicker.institutionCard.closeButtonLabel')}
          className={classes.removeCardButton}
        >
          <CloseIcon color={(theme) => theme.palette.shipCove.main} />
        </IconButton>
        <Text paragraph color='grey-60' style={{ marginTop: '5px' }}>
          {`${institutionAddress.address}, \n${institutionAddress.city}, ${institutionAddress.province} \n${
            institutionAddress.postal_code || ''
          }`}
        </Text>
      </div>
      <div>
        <div className={classes.inputContainer}>
          {physician && !showPhysicanPicker && (
            <>
              <Text paragraph fontWeight={400}>
                {t('physicianAndInstitutionPicker.institutionCard.selectedOptions.physicianLabel')}
              </Text>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ maxWidth: '80%' }}>
                  <Text paragraph style={{ marginRight: '0.5rem' }}>
                    {physician.first_name} {physician.last_name}
                  </Text>
                </div>

                <IconButton
                  onClick={removePhysician}
                  label={t('physicianAndInstitutionPicker.institutionCard.selectedOptions.clearButtonText')}
                  color='primary'
                >
                  <CloseIcon color={(theme) => theme.palette.shipCove.main} />
                </IconButton>
              </div>
            </>
          )}
          {!physician && (
            <ButtonText
              isPrimary
              onClick={() => setShowPhysicianPicker(true)}
              label={t('physicianAndInstitutionPicker.institutionCard.specifyPhysicianButtonText')}
            />
          )}
          {showPhysicanPicker && (
            <PhysicianInput
              institutionAddress={institutionAddress}
              onPhysicianSelect={(physician) => {
                selectPhysicianForInstitution(physician);
                setShowPhysicianPicker(false);
              }}
              address_id={institutionAddress.id}
            />
          )}
        </div>
        <div className={classes.inputContainer}>
          <ButtonText
            isPrimary
            onClick={() => setShowNotesInput(true)}
            label={t('physicianAndInstitutionPicker.institutionCard.addNoteButtonText')}
          />
          {showNotesInput && (
            <Input hideLabel value={notes} onChange={(e) => setNotesForRequest(e.target.value)} name='notes' maxWidth />
          )}
        </div>
      </div>
    </Card>
  );
};

export default InstitutionCard;
