import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import PartnerLogo from './shared/PartnerLogo';
import { AuthContext } from '../contexts/auth-context';

export interface AppNavProps {
  logout?: () => void;
  logoutUser?: any;
  className?: string;
  public?: boolean;
}

const AppNav = (props: AppNavProps) => {
  const { institution } = useContext(AuthContext);

  return (
    <nav className={clsx('navbar', props.className)}>
      <div className={clsx('navbar-menu')}>
        <div className='navbar-left'>
          {institution && (
            <>
              <PartnerLogo partnerName={institution} />
              <Link className='navbar-institution' to='/'>
                {institution}
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default AppNav;
