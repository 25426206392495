import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AppView from '../layouts/AppView';
import GenericErrorScreen from './GenericErrorScreen';
import Can from './Can';
import PageContainer from './PageContainer';
import { AuthContext } from '../../contexts/auth-context';
import Spinner from './Spinner';

export interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any> | undefined;
  perform?: string;
  noWrap?: boolean;
}

const PrivateRoute = ({ component: Component, perform, noWrap, ...rest }: PrivateRouteProps) => {
  const Wrapper = noWrap ? React.Fragment : AppView;
  const { institution, portalEnabled, isPortalPingLoading } = useContext(AuthContext);

  if (isPortalPingLoading) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  if (!institution || !portalEnabled) {
    return <GenericErrorScreen is404 />;
  }

  return (
    <Route
      {...rest}
      component={(props: any) => {
        return (
          <Wrapper>
            {perform ? (
              <Can
                perform={perform}
                fallback={() => {
                  return (
                    <PageContainer>
                      <GenericErrorScreen />
                    </PageContainer>
                  );
                }}
              >
                {React.createElement(Component!, props)}
              </Can>
            ) : (
              React.createElement(Component!, props)
            )}
          </Wrapper>
        );
      }}
    />
  );
};

export default PrivateRoute;
