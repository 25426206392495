/* eslint-disable max-len */
import { useEffect } from 'react';

/**
 * This Can component incorporates some parts of an auth0 blog post about RBAC.
 * Link to the post: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 *
 * A general overview:
 *  - STATIC permissions are those permissions which don't need any data apart from the user role in order to determine access
 *  - DYNAMIC permissions (not added yet) would be those which need some data to check if the user has access for ex only creator of the comment can edit a comment
 */

interface CanComponentProps {
  perform: string | string[];
  fallback?: () => JSX.Element;
  children: React.ReactNode;
}

/**
 *
 * @param action The action(s) that the user want to perform
 * @param strict
 * #### NOTE:  This param would only be used when passing an array to the action param.
 *
 *
 * If `true` it would check that the user has permission to perform all the given actions
 *
 *
 * If `false` it would check if the user has permission to perform at least one of the given actions
 * @returns
 */
//TODO: remove onces this is finalized
// eslint-disable-next-line
export const useCheckPermission = (action: string | string[], strict = true) => {
  return false;
};

const Can: React.FC<CanComponentProps> = ({ children, perform, fallback }: CanComponentProps) => {
  const isAuthorized = useCheckPermission(perform);

  useEffect(() => {
    if (!isAuthorized) {
      console.warn('Unable to perform', perform);
    }
  }, [isAuthorized, perform]);

  if (isAuthorized) return <>{children}</>;

  return typeof fallback !== 'undefined' ? fallback() : <></>;
};

export default Can;
