import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import Button from '../../shared/Button';
import Text from '../../shared/Typography';
import PageContainer from '../../shared/PageContainer';
import Icon from '../../shared/Icon';
import Container from '../../shared/Container';
import Skeleton from '../../shared/Skeleton';
import { useTranslate } from '../../../hooks/useTranslate';
import { useRoutes } from '../../../hooks/useRoutes';
import { AuthContext } from '../../../contexts/auth-context';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>div': {
      width: '95%'
    }
  },
  messageContainer: {
    padding: '4rem',
    maxWidth: '55rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent'
    }
  },
  titleText: {
    marginBottom: '2rem',
    [theme.breakpoints.up('xs')]: {
      marginBottom: '3rem'
    }
  },
  descriptionText: {
    maxWidth: '27rem',
    marginBottom: '4rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  titleSkeleton: {
    width: '27.7rem',
    height: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  descriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  }
}));

const RequestSucces = () => {
  const classes = useStyles();
  const { t, ready: isRequestFormTranslationReady } = useTranslate('requestSuccess');
  const { routesTFunction } = useRoutes();
  const { institution } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <PageContainer className={classes.messageContainer}>
        <Icon size='xx-large' name='request-submitted' />
        <Text align='center' className={classes.titleText} h2 color='ship-cove'>
          {isRequestFormTranslationReady ? t('title') : <Skeleton className={classes.titleSkeleton} />}
        </Text>
        <Text align='center' className={classes.descriptionText} paragraph color='ship-cove'>
          {isRequestFormTranslationReady ? t('description') : <Skeleton className={classes.descriptionSkeleton} />}
        </Text>
        <Container className={classes.buttonContainer}>
          {isRequestFormTranslationReady ? (
            <Button
              urlTo={routesTFunction('routerPaths./')}
              label={t('returnButton', { institutionName: institution })}
            />
          ) : (
            <Skeleton width='10rem' height='4.6rem' />
          )}
        </Container>
      </PageContainer>
    </div>
  );
};

export default RequestSucces;
