//@flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from './Skeleton';
import Text from './Typography';
import PageContainer from './PageContainer';
import { useTranslate } from '../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>div': {
      width: '95%'
    }
  },
  messageContainer: {
    padding: '4rem',
    maxWidth: '55rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent'
    }
  },
  titleText: {
    marginBottom: '2rem'
  },
  descriptionText: {
    maxWidth: '27rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  titleSkeleton: {
    width: '27.7rem',
    height: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  descriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  }
}));
export interface GenericErrorScreenProps {
  is404?: boolean;
}

const GenericErrorScreen: React.FC<GenericErrorScreenProps> = ({ is404 }) => {
  const { t, ready } = useTranslate('genericErrorScreen');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageContainer className={classes.messageContainer}>
        <Text align='center' className={classes.titleText} h1 color='ship-cove'>
          {is404 && 404}
        </Text>
        <Text align='center' className={classes.descriptionText} paragraph color='ship-cove'>
          {ready ? t('404') : <Skeleton className={classes.descriptionSkeleton} />}
        </Text>
      </PageContainer>
    </div>
  );
};

export default GenericErrorScreen;
