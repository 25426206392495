import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: 1,
    margin: 0,
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  }
}));

export interface DividerProps extends React.HTMLAttributes<HTMLHRElement> {}

const Divider = (props: DividerProps) => {
  const { className, ...other } = props;
  const classes = useStyles();

  return <hr className={clsx(classes.root, className)} {...other} />;
};

export default Divider;
