import moment from 'moment';
import 'moment/locale/fr-ca';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Namespace, useTranslation, UseTranslationOptions } from 'react-i18next';
import apiService from '../services/api-service';

export type TranslationResourceNameSpace = Namespace;

export type SupportedLanguage = 'en-CA' | 'fr-CA';

export const useTranslate = <T extends TranslationResourceNameSpace>(ns?: T, options?: UseTranslationOptions) => {
  const { i18n, t, ready } = useTranslation<T>(ns, { useSuspense: false, ...options });
  const [language, setLanguage] = useState(i18n.language as SupportedLanguage);
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
    return () => {
      mountRef.current = false;
    };
  }, []);

  useEffect(() => {
    apiService.setLanguage(language);
  }, [language]);

  useEffect(() => {
    if (language && mountRef.current) moment.locale(language);
  }, [language]);

  useEffect(() => {
    if (i18n.isInitialized && mountRef.current && i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const changeLanguage = useCallback((language: SupportedLanguage) => {
    setLanguage(language);
  }, []);

  return {
    i18n,
    t,
    language: i18n.language as SupportedLanguage,
    changeLanguage,
    ready,
    isInitialized: i18n.isInitialized
  };
};

export const useLoadTranslation = () => {
  const [asyncLoadedNameSpaces, setAsyncLoadedNameSpaces] = useState<TranslationResourceNameSpace>();
  const asyncTranslation = useTranslate(asyncLoadedNameSpaces, { useSuspense: false });

  const loadTranslationsAsync = useCallback((nameSpaces: TranslationResourceNameSpace) => {
    setAsyncLoadedNameSpaces((prev) => {
      if (!prev) return nameSpaces;
      if (typeof prev === 'string') {
        if (typeof nameSpaces === 'string') {
          return [prev, nameSpaces];
        }
        return [prev, ...nameSpaces];
      }
      if (typeof nameSpaces === 'string') {
        return [...prev, nameSpaces];
      }
      return [...prev, ...nameSpaces];
    });
  }, []);

  return { ...asyncTranslation, loadTranslationsAsync };
};
