/* eslint-disable max-len */
/**
 * The routes object is created here as a regular constant and not as a json file
 * like all other translations because the routes are important to be loaded first
 * and sometimes loading them asynchronously(by having them as json) i18n would fallback to
 * the translation key which shouldn't happen under any scenario.
 *
 * NOTE: Add any routes translation file as a resource bundle to i18n in './i18n.ts' file similar to how routesENCA is added.
 * NOTE: Routes accessing a client, request or resource should use internal id
 */

export const routesENCA = {
  routerPaths: {
    '/request/new': '/request/new',
    '/request/successful': '/request/successful',
    '/verify/:token': '/verify/:token',
    '/': '/'
  }
};
