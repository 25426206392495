import moment from 'moment';
import { VersionedText, VersionedTextReplacement } from '../types/common/VersionedText';

export const provinces: Provinces[] = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Québec',
  'Saskatchewan',
  'Yukon'
];
export type Provinces =
  | 'Newfoundland and Labrador'
  | 'Nova Scotia'
  | 'Prince Edward Island'
  | 'New Brunswick'
  | 'Nunavut'
  | 'Québec'
  | 'Ontario'
  | 'Manitoba'
  | 'Saskatchewan'
  | 'Alberta'
  | 'British Columbia'
  | 'Northwest Territories'
  | 'Yukon';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const postalCodeRegex = /^([ABCEGHJ-NPRSTVXY])(\d)([ABCEGHJ-NPRSTV-Z])[ -]?(\d)([ABCEGHJ-NPRSTV-Z])(\d)$/i;
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// mostly obtained from https://www.gov.nl.ca/hcs/files/mcp-providers-pim-residents-of-other-provinces.pdf
export const HCN_REGEXES: { [key in Provinces]: RegExp } = {
  Ontario: /(\d{4})(\d{3})(\d{3})(\D{0,2})/,
  'Prince Edward Island': /(\d{4})(\d{4})/,
  Alberta: /(\d{5})(\d{4})/,
  'British Columbia': /(\d{4})(\d{3})(\d{3})/,
  'Nova Scotia': /(\d{4})(\d{3})(\d{3})/,
  Manitoba: /(\d{3})(\d{3})(\d{3})/,
  'New Brunswick': /(\d{3})(\d{3})(\d{3})/,
  Nunavut: /(\d{3})(\d{3})(\d{3})/,
  Saskatchewan: /(\d{3})(\d{3})(\d{3})/,
  Yukon: /(\d{3})(\d{3})(\d{3})/,
  'Newfoundland and Labrador': /(\d{0,12})/,
  'Northwest Territories': /([nN]\d{7})/,
  Québec: /([a-zA-Z]{4})(\d{4})(\d{4})/
};

export const EXAMPLE_HCNS = {
  Ontario: '1212-121-121-AM',
  'Prince Edward Island': '1212-1212',
  Alberta: '12345-6789',
  'British Columbia': '1212-121-121',
  'Nova Scotia': '1234-567-897',
  Manitoba: '123-123-123',
  'New Brunswick': '123-123-123',
  Nunavut: '123-123-123',
  Saskatchewan: '123-123-123',
  Yukon: '123-123-123',
  'Newfoundland and Labrador': '123456789123',
  'Northwest Territories': 'N1234567',
  Québec: 'ABCD-1234-1234'
};

export const truncateStr = (str: string, number: number) => {
  if (str.length <= number) return str;
  return str.slice(0, number) + '...';
};

export const isProd = process.env.REACT_APP_NODE_ENV === 'production';
export const isDev = process.env.REACT_APP_NODE_ENV === 'dev';

export function isNotUndefined<T>(arg: T | undefined): arg is T {
  return typeof arg !== 'undefined';
}

export function isEmptyArray(arr: any[] | undefined) {
  return !Array.isArray(arr) || !arr.length;
}

export function isNotEmptyArray<T>(arr: T[] | undefined): arr is T[] {
  return !isEmptyArray(arr);
}

export const getToken = () => {
  return localStorage.getItem('access_token');
};

export const generateKey = (...args: any) => {
  if (args) {
    return [...args]
      .filter((item) => !!item)
      .map((item) => {
        if (typeof item === 'object') return JSON.stringify(item);
        if (typeof item === 'function') return '';
        return item?.toString();
      })
      .join('-');
  }
  return (Math.random() * 1e9).toString();
};

export const deepEqual = (x: any, y: any) => {
  if (x === y) {
    return true;
  }
  if (typeof x === 'object' && x !== null && typeof y === 'object' && y !== null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (const prop in x) {
      if (isNotUndefined(y[prop])) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else {
        return false;
      }
    }

    return true;
  }
  return false;
};

export function conditionalProps<T>(props: T, condition: boolean) {
  if (condition) {
    return props;
  }
}

export const hexToRGB = (hex: string, opacity?: number) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result![1], 16)},
  ${parseInt(result![2], 16)},
  ${parseInt(result![3], 16)}
  ${opacity && ',' + opacity})`;
};

export const isValidDOB = (dob: string | Date) => {
  return moment(dob, 'YYYY-MM-DD').isBetween(
    moment().subtract(120, 'years').format('YYYY-MM-DD'),
    moment().subtract(18, 'years').format('YYYY-MM-DD'),
    undefined,
    '[]'
  );
};

export const isValidHCN = (value: string, province: keyof typeof HCN_REGEXES) => {
  const rawValue = value.replace(/[^A-Za-z0-9]/g, '');
  if (!rawValue || rawValue.length < 1) {
    return false;
  }
  if (!HCN_REGEXES[province]) return true;
  const entireMatchRegex = new RegExp('^' + HCN_REGEXES[province].source + '$');
  return entireMatchRegex.test(rawValue);
};

export const isCanadianHCN = (value: string) => {
  return !!Object.keys(HCN_REGEXES).find((province) => isValidHCN(value, province as Provinces));
};

export const isValidEmail = (email: string) => {
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (number: string) => {
  return phoneRegex.test(number);
};

export const isValidPostalCode = (code: string) => {
  return postalCodeRegex.test(code);
};

export const generateRandomId = (prefix?: string) => {
  const randomValue = Math.random().toString(36).substring(2, 15);
  return prefix ? generateKey(prefix, randomValue) : randomValue;
};

export function isEmptyObject<T extends Record<string | number, unknown>>(object: T): boolean {
  return isEmptyArray(Object.keys(object));
}

/**
 * @param str The string to check
 * @returns `true` if the param string only contains whitespaces else returns `false`
 */
export const isBlankString = (str: string) => {
  return !str || /^\s*$/.test(str);
};

/**
 * Replaces parts of a string by key with specified replacements
 * @param text
 * @param replacements
 * @returns
 */
export const replaceVersionedText = (text: VersionedText, replacements: { [key: string]: string }) => {
  let replacedText = text.display_text;
  text.replacements.forEach((replacement) => {
    if (replacement.type !== 'link' && replacements[replacement.key]) {
      replacedText = replacedText.replace(`{{${replacement.key}}}`, replacements[replacement.key]);
    }
  });

  return replacedText;
};

/**
 * Finds the specified replacement by key in a versioned text object
 * @param versionedText
 * @param replacementKey
 * @returns
 */
export const getReplacementFromVersionedText = (versionedText: VersionedText, replacementKey: string) => {
  if (versionedText?.replacements.length > 0) {
    return versionedText.replacements.find(
      (replacement: VersionedTextReplacement) => replacement.key === replacementKey
    );
  }
};
