import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../shared/Button';
import Text from '../../shared/Typography';
import PageContainer from '../../shared/PageContainer';
import Icon from '../../shared/Icon';
import { LOCALSTORAGE, PARTNERED_INSTITUTIONS } from '../../../utils/constants';
import { useMutation } from '../../../hooks/useQuery';
import { PersonalInformationRequest } from '.';
import { AuthContext } from '../../../contexts/auth-context';
import Container from '../../shared/Container';
import Skeleton from '../../shared/Skeleton';
import { useTranslate } from '../../../hooks/useTranslate';
import { PersonalInformationType } from '../../../types';
import Input from '../../shared/Input';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '4rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },

  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  titleSkeleton: {
    height: '3.2rem',
    width: '30rem'
  },
  descriptionSkeleton: {
    width: '27rem',
    height: '4.8rem',
    [theme.breakpoints.up('xs')]: {
      width: '46rem',
      height: '2.4rem'
    }
  },
  messageContainer: {
    width: '100%',
    maxWidth: '54rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent'
    },
    padding: '2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem'
    }
  },
  messageTitleText: {
    marginBottom: '2.6rem',
    [theme.breakpoints.up('xs')]: {
      marginBottom: '3.6rem'
    }
  },
  messageDescriptionText: {
    maxWidth: '27rem',
    marginBottom: '3rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  messageTitleSkeleton: {
    width: '27.7rem',
    height: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  messageDescriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  button: {
    marginBottom: '1rem'
  }
}));

interface VerifyEmailProps {
  personalInformation?: PersonalInformationType;
  sendEmailVerification: (data: PersonalInformationRequest, callback?: () => void) => void;
  sendEmailVerificationLoading: boolean;
  sendEmailVerificationError: string;
  handleNext: () => void;
}

interface PublicAccessToken {
  access_token: string;
}

const VerifyEmail = ({
  personalInformation,
  sendEmailVerification,
  sendEmailVerificationLoading,
  sendEmailVerificationError,
  handleNext
}: VerifyEmailProps) => {
  const classes = useStyles();
  const { institution } = useContext(AuthContext);
  const { loading: verifyEmailLoading, makeRequest, error: verifyEmailError } = useMutation<PublicAccessToken>('POST');
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const verifyEmailTimeoutMultiplier = 30;
  const resendEmailTimeoutMultiplier = 30;
  const maxClickToTimeout = 2;

  const verifyTimerRef = useRef(null);
  const resendTimerRef = useRef(null);

  const [verifyEmailCode, setVerifyEmailCode] = useState('');
  const [resendTimer, setResendTimer] = useState('00:00');
  const [verifyTimer, setVerifyTimer] = useState('00:00');
  const [resendButtonClicks, setResendButtonClicks] = useState(0);
  const [disableResendEmail, setDisableResendEmail] = useState(false);
  const [verifyButtonClicks, setVerifyButtonClicks] = useState(0);
  const [disableVerifyEmail, setDisableVerifyEmail] = useState(false);

  const verifyEmailRef = useRef<any>();
  const resendTimeouRef = useRef<any>();

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date() as unknown as string);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds
    };
  };

  const startTimer = (e: any, setTimeCallback: (time: string) => void) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimeCallback((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds));
    }
  };

  const clearTimer = (e: any, setTimeCallback: (time: string) => void, ref: any) => {
    startTimer(e, setTimeCallback);

    if (ref.current) clearInterval(ref.current);
    const id = setInterval(() => {
      startTimer(e, setTimeCallback);
    }, 1000);
    ref.current = id;
  };

  const getDeadTime = (seconds: number) => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + seconds);
    return deadline;
  };

  useEffect(() => {
    if (disableResendEmail) {
      clearTimer(getDeadTime(resendButtonClicks * resendEmailTimeoutMultiplier), setResendTimer, resendTimerRef);
    }
    if (disableVerifyEmail) {
      clearTimer(getDeadTime(verifyButtonClicks * verifyEmailTimeoutMultiplier), setVerifyTimer, verifyTimerRef);
    }
    // eslint-disable-next-line
  }, [disableResendEmail, disableVerifyEmail]);

  const incrementResendButtonClick = () => {
    setResendButtonClicks((prevState) => prevState + 1);
  };

  const incrementVerifyButtonClick = () => {
    setVerifyButtonClicks((prevState) => prevState + 1);
  };

  const resendVerificationEmail = () => {
    /**
     * If a user has clicked the button more than 2 times we disable it for
     * (resendEmailTimeoutMultiplier * 1000 * resendButtonClicks)ms
     */
    if (resendButtonClicks > maxClickToTimeout) {
      setDisableResendEmail(true);

      resendTimeouRef.current = setTimeout(() => {
        setDisableResendEmail(false);
      }, resendEmailTimeoutMultiplier * 1000 * resendButtonClicks);
    }

    incrementResendButtonClick();

    if (personalInformation) {
      sendEmailVerification({
        user: personalInformation,
        institution_name: institution as keyof typeof PARTNERED_INSTITUTIONS
      });
    }
  };

  const verifyEmailToken = async () => {
    /**
     * If a user has clicked the button more than 5 times we disable it for
     * {verifyEmailTimeoutMultiplier * 1000 * verifyButtonClicks}ms
     */
    if (verifyButtonClicks > maxClickToTimeout) {
      setDisableVerifyEmail(true);

      verifyEmailRef.current = setTimeout(() => {
        setDisableVerifyEmail(false);
      }, verifyEmailTimeoutMultiplier * 1000 * verifyButtonClicks);
    }

    incrementVerifyButtonClick();

    const publicAccessToken = await makeRequest(`/verify-email/${verifyEmailCode}`);

    if (publicAccessToken && publicAccessToken.access_token) {
      localStorage.setItem(LOCALSTORAGE.publicAccessToken, publicAccessToken.access_token);
      handleNext();
    } else {
      throw new Error('Something went wrong');
    }
  };

  const getResendEmailText = () => {
    if (disableResendEmail) {
      return requestFormTFunction('errors.tryAgainResend', {
        resendSeconds: resendTimer
      });
    }
    if (sendEmailVerificationLoading) {
      return '';
    }
    return requestFormTFunction('emailVerification.resend');
  };

  const getVerifyEmailText = () => {
    if (disableVerifyEmail && !verifyEmailLoading) {
      return requestFormTFunction('errors.tryAgainVerify', {
        verifySeconds: verifyTimer
      });
    }
    if (verifyEmailLoading) {
      return '';
    }
    return requestFormTFunction('emailVerification.verifiedEmail');
  };

  const getVerifyEmailErrorText = () => {
    if (verifyEmailError && !verifyEmailCode) {
      return requestFormTFunction('errors.invalidCode');
    }

    return requestFormTFunction('errors.verifyEmailError');
  };

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('emailVerification.title')
          ) : (
            <Skeleton className={classes.titleSkeleton} />
          )}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('emailVerification.description')
          ) : (
            <Skeleton className={classes.descriptionSkeleton} />
          )}
        </Text>
      </div>
      <PageContainer className={classes.messageContainer}>
        <Icon size='xx-large' name='email' />
        <Text align='center' className={classes.messageTitleText} h2 color='ship-cove'>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('emailVerification.messageTitle')
          ) : (
            <Skeleton className={classes.messageTitleSkeleton} />
          )}
        </Text>
        <Text align='center' className={classes.messageDescriptionText} paragraph color='ship-cove'>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('emailVerification.messageDescription', { email: personalInformation?.email })
          ) : (
            <Skeleton className={classes.messageDescriptionSkeleton} />
          )}
        </Text>
        <Input
          style={{ margin: 0, textAlign: 'center', padding: '1.2rem 3.5rem' }}
          hideLabel
          value={verifyEmailCode}
          onChange={(e) => {
            setVerifyEmailCode(e.target.value);
          }}
          name='verifyEmailCode'
          maxWidth
        />
        <Container className={classes.buttonContainer}>
          {isRequestFormTranslationReady ? (
            <Button
              style={{ marginBottom: '1rem', minWidth: '17.5rem' }}
              label={getVerifyEmailText()}
              onClick={verifyEmailToken}
              isLoading={verifyEmailLoading}
              isDisabled={disableVerifyEmail}
            />
          ) : (
            <Skeleton width='10rem' height='4.6rem' />
          )}
        </Container>
        <Container className={classes.buttonContainer}>
          {isRequestFormTranslationReady ? (
            <Button
              isSecondary
              style={{
                border: 'none',
                background: 'transparent',
                color: disableResendEmail ? '#fff' : '#672bc2'
              }}
              label={getResendEmailText()}
              onClick={resendVerificationEmail}
              isLoading={sendEmailVerificationLoading}
              isDisabled={disableResendEmail}
            />
          ) : (
            <Skeleton width='10rem' height='4.6rem' />
          )}
        </Container>
        {verifyEmailError && !disableVerifyEmail && (
          <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
            {getVerifyEmailErrorText()}
          </Text>
        )}
        {sendEmailVerificationError && !disableResendEmail && (
          <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
            {requestFormTFunction('errors.resendEmailError')}
          </Text>
        )}
      </PageContainer>
    </Container>
  );
};

export default VerifyEmail;
