import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { AuthContextType } from '../types/common/auth-context-types';
import { isDev, isNotUndefined } from '../utils';
import { PARTNERED_INSTITUTIONS } from '../utils/constants';
import config from '../config';
import apiService from '../services/api-service';

const AuthContext = React.createContext<AuthContextType>({
  institution: undefined,
  portalEnabled: false,
  isPortalPingLoading: true
});

type AuthProviderProps = React.PropsWithChildren<Record<string, unknown>>;

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [institution, setInstitution] = useState<keyof typeof PARTNERED_INSTITUTIONS>();
  const [portalEnabled, setPortalEnabled] = useState<boolean>(false);
  const apiEndpoint = config.API_ENDPOINT;

  const queryKey = useMemo(() => `${institution}-ping-portal-scope-ping`, [institution]);

  const {
    status: portalPingStatus,
    error: portalPingError,
    isLoading: isPortalPingLoading
  } = useQuery(
    queryKey,
    () => {
      return apiService.get(`/${institution}/ping`);
    },
    {
      staleTime: 0,
      cacheTime: 0
    }
  );

  useEffect(() => {
    if (portalPingStatus === 'success' && !portalPingError) {
      setPortalEnabled(true);
    } else {
      setPortalEnabled(false);
    }
  }, [portalPingStatus, portalPingError]);

  useEffect(() => {
    if (isDev) {
      if (isNotUndefined(process.env.REACT_APP_PARTNERTED_INSTITUTION)) {
        setInstitution(process.env.REACT_APP_PARTNERTED_INSTITUTION as keyof typeof PARTNERED_INSTITUTIONS);
        apiService.setEndpoint(`${apiEndpoint}/public/portal`);
      } else {
        throw new Error('Missing dev environment variable for partnered institution');
      }
    } else {
      window.location.host.split('.').forEach((name) => {
        const formattedName = name.toLocaleLowerCase();
        if (isNotUndefined(PARTNERED_INSTITUTIONS[formattedName])) {
          setInstitution(PARTNERED_INSTITUTIONS[formattedName]);
          apiService.setEndpoint(`${apiEndpoint}/public/portal`);
        }
      });
    }
  }, [apiEndpoint]);

  return (
    <AuthContext.Provider
      value={{
        institution,
        portalEnabled,
        isPortalPingLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
